/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CheckSyncSfObjectMapping200Response } from '../models';
// @ts-ignore
import { GetSfObjectMappings200Response } from '../models';
// @ts-ignore
import { GetSfObjectMappingsKeys200Response } from '../models';
// @ts-ignore
import { UpsertSfObjectMappingBodyRequest } from '../models';
/**
 * SfObjectMappingsApi - axios parameter creator
 * @export
 */
export const SfObjectMappingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Salesforceのマッピングが反映中かチェック
         * @summary Salesforceのマッピングが反映中かチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSyncSfObjectMapping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/sf_object_mappings/check_sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Salesforceのマッピングしたいオブジェクト情報
         * @summary Salesforceのマッピングしたいオブジェクト情報
         * @param {string} targetObject SF連携でマッピングする対象オブジェクト名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSfObjectMappings: async (targetObject: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetObject' is not null or undefined
            assertParamExists('getSfObjectMappings', 'targetObject', targetObject)
            const localVarPath = `/api/user/salesforce/sf_object_mappings/target_object`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (targetObject !== undefined) {
                localVarQueryParameter['target_object'] = targetObject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Salesforceのマッピングしたいオブジェクト一覧
         * @summary Salesforceのマッピングしたいオブジェクト一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSfObjectMappingsKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/sf_object_mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Salesforceのマッピングを反映する
         * @summary Salesforceのマッピングを反映する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSfObjectMapping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/sf_object_mappings/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Salesforceのマッピングしたいオブジェクト情報
         * @summary Salesforceのマッピングしたいオブジェクト情報の更新
         * @param {UpsertSfObjectMappingBodyRequest} [upsertSfObjectMappingBodyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertSfObjectMappingBody: async (upsertSfObjectMappingBodyRequest?: UpsertSfObjectMappingBodyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/sf_object_mappings/upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertSfObjectMappingBodyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SfObjectMappingsApi - functional programming interface
 * @export
 */
export const SfObjectMappingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SfObjectMappingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Salesforceのマッピングが反映中かチェック
         * @summary Salesforceのマッピングが反映中かチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkSyncSfObjectMapping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckSyncSfObjectMapping200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkSyncSfObjectMapping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Salesforceのマッピングしたいオブジェクト情報
         * @summary Salesforceのマッピングしたいオブジェクト情報
         * @param {string} targetObject SF連携でマッピングする対象オブジェクト名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSfObjectMappings(targetObject: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSfObjectMappings200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSfObjectMappings(targetObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Salesforceのマッピングしたいオブジェクト一覧
         * @summary Salesforceのマッピングしたいオブジェクト一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSfObjectMappingsKeys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSfObjectMappingsKeys200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSfObjectMappingsKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Salesforceのマッピングを反映する
         * @summary Salesforceのマッピングを反映する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncSfObjectMapping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncSfObjectMapping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Salesforceのマッピングしたいオブジェクト情報
         * @summary Salesforceのマッピングしたいオブジェクト情報の更新
         * @param {UpsertSfObjectMappingBodyRequest} [upsertSfObjectMappingBodyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertSfObjectMappingBody(upsertSfObjectMappingBodyRequest?: UpsertSfObjectMappingBodyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertSfObjectMappingBody(upsertSfObjectMappingBodyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SfObjectMappingsApi - factory interface
 * @export
 */
export const SfObjectMappingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SfObjectMappingsApiFp(configuration)
    return {
        /**
         * Salesforceのマッピングが反映中かチェック
         * @summary Salesforceのマッピングが反映中かチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSyncSfObjectMapping(options?: any): AxiosPromise<CheckSyncSfObjectMapping200Response> {
            return localVarFp.checkSyncSfObjectMapping(options).then((request) => request(axios, basePath));
        },
        /**
         * Salesforceのマッピングしたいオブジェクト情報
         * @summary Salesforceのマッピングしたいオブジェクト情報
         * @param {string} targetObject SF連携でマッピングする対象オブジェクト名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSfObjectMappings(targetObject: string, options?: any): AxiosPromise<GetSfObjectMappings200Response> {
            return localVarFp.getSfObjectMappings(targetObject, options).then((request) => request(axios, basePath));
        },
        /**
         * Salesforceのマッピングしたいオブジェクト一覧
         * @summary Salesforceのマッピングしたいオブジェクト一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSfObjectMappingsKeys(options?: any): AxiosPromise<GetSfObjectMappingsKeys200Response> {
            return localVarFp.getSfObjectMappingsKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * Salesforceのマッピングを反映する
         * @summary Salesforceのマッピングを反映する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSfObjectMapping(options?: any): AxiosPromise<boolean> {
            return localVarFp.syncSfObjectMapping(options).then((request) => request(axios, basePath));
        },
        /**
         * Salesforceのマッピングしたいオブジェクト情報
         * @summary Salesforceのマッピングしたいオブジェクト情報の更新
         * @param {UpsertSfObjectMappingBodyRequest} [upsertSfObjectMappingBodyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertSfObjectMappingBody(upsertSfObjectMappingBodyRequest?: UpsertSfObjectMappingBodyRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.upsertSfObjectMappingBody(upsertSfObjectMappingBodyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SfObjectMappingsApi - interface
 * @export
 * @interface SfObjectMappingsApi
 */
export interface SfObjectMappingsApiInterface {
    /**
     * Salesforceのマッピングが反映中かチェック
     * @summary Salesforceのマッピングが反映中かチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfObjectMappingsApiInterface
     */
    checkSyncSfObjectMapping(options?: AxiosRequestConfig): AxiosPromise<CheckSyncSfObjectMapping200Response>;

    /**
     * Salesforceのマッピングしたいオブジェクト情報
     * @summary Salesforceのマッピングしたいオブジェクト情報
     * @param {string} targetObject SF連携でマッピングする対象オブジェクト名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfObjectMappingsApiInterface
     */
    getSfObjectMappings(targetObject: string, options?: AxiosRequestConfig): AxiosPromise<GetSfObjectMappings200Response>;

    /**
     * Salesforceのマッピングしたいオブジェクト一覧
     * @summary Salesforceのマッピングしたいオブジェクト一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfObjectMappingsApiInterface
     */
    getSfObjectMappingsKeys(options?: AxiosRequestConfig): AxiosPromise<GetSfObjectMappingsKeys200Response>;

    /**
     * Salesforceのマッピングを反映する
     * @summary Salesforceのマッピングを反映する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfObjectMappingsApiInterface
     */
    syncSfObjectMapping(options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * Salesforceのマッピングしたいオブジェクト情報
     * @summary Salesforceのマッピングしたいオブジェクト情報の更新
     * @param {UpsertSfObjectMappingBodyRequest} [upsertSfObjectMappingBodyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfObjectMappingsApiInterface
     */
    upsertSfObjectMappingBody(upsertSfObjectMappingBodyRequest?: UpsertSfObjectMappingBodyRequest, options?: AxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * Request parameters for getSfObjectMappings operation in SfObjectMappingsApi.
 * @export
 * @interface SfObjectMappingsApiGetSfObjectMappingsRequest
 */
export interface SfObjectMappingsApiGetSfObjectMappingsRequest {
    /**
     * SF連携でマッピングする対象オブジェクト名
     * @type {string}
     * @memberof SfObjectMappingsApiGetSfObjectMappings
     */
    readonly targetObject: string
}

/**
 * Request parameters for upsertSfObjectMappingBody operation in SfObjectMappingsApi.
 * @export
 * @interface SfObjectMappingsApiUpsertSfObjectMappingBodyRequest
 */
export interface SfObjectMappingsApiUpsertSfObjectMappingBodyRequest {
    /**
     * 
     * @type {UpsertSfObjectMappingBodyRequest}
     * @memberof SfObjectMappingsApiUpsertSfObjectMappingBody
     */
    readonly upsertSfObjectMappingBodyRequest?: UpsertSfObjectMappingBodyRequest
}

/**
 * SfObjectMappingsApi - object-oriented interface
 * @export
 * @class SfObjectMappingsApi
 * @extends {BaseAPI}
 */
export class SfObjectMappingsApi extends BaseAPI implements SfObjectMappingsApiInterface {
    /**
     * Salesforceのマッピングが反映中かチェック
     * @summary Salesforceのマッピングが反映中かチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfObjectMappingsApi
     */
    public checkSyncSfObjectMapping(options?: AxiosRequestConfig) {
        return SfObjectMappingsApiFp(this.configuration).checkSyncSfObjectMapping(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Salesforceのマッピングしたいオブジェクト情報
     * @summary Salesforceのマッピングしたいオブジェクト情報
     * @param {SfObjectMappingsApiGetSfObjectMappingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfObjectMappingsApi
     */
    public getSfObjectMappings(requestParameters: SfObjectMappingsApiGetSfObjectMappingsRequest, options?: AxiosRequestConfig) {
        return SfObjectMappingsApiFp(this.configuration).getSfObjectMappings(requestParameters.targetObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Salesforceのマッピングしたいオブジェクト一覧
     * @summary Salesforceのマッピングしたいオブジェクト一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfObjectMappingsApi
     */
    public getSfObjectMappingsKeys(options?: AxiosRequestConfig) {
        return SfObjectMappingsApiFp(this.configuration).getSfObjectMappingsKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Salesforceのマッピングを反映する
     * @summary Salesforceのマッピングを反映する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfObjectMappingsApi
     */
    public syncSfObjectMapping(options?: AxiosRequestConfig) {
        return SfObjectMappingsApiFp(this.configuration).syncSfObjectMapping(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Salesforceのマッピングしたいオブジェクト情報
     * @summary Salesforceのマッピングしたいオブジェクト情報の更新
     * @param {SfObjectMappingsApiUpsertSfObjectMappingBodyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfObjectMappingsApi
     */
    public upsertSfObjectMappingBody(requestParameters: SfObjectMappingsApiUpsertSfObjectMappingBodyRequest = {}, options?: AxiosRequestConfig) {
        return SfObjectMappingsApiFp(this.configuration).upsertSfObjectMappingBody(requestParameters.upsertSfObjectMappingBodyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
