import { AxiosResponse } from 'axios';
import { computed, readonly, ref } from 'vue';
import type { SequenceStepMaster, MailAggregates, CallAggregates } from '@/api/openapi';
import { SequenceStepMasterApiService } from '@/api/user/resources/sequence_step_master';
import type { TFormSequenceStep } from '@/components/organisms/user/general/sequence/types';
import { useWait } from '@/composable/vue-wait';
import { makeNextActionType } from '../nextActionType/next-action-types';
import { getActionDetail } from './post-put-body';

const useSequenceStepMasters = (sequenceMasterId?: number) => {
  const sequenceStepMasters = ref<SequenceStepMaster[]>([]);
  const {
    doActionWithWait,
  } = useWait();  
  const fetchSequenceStepMasters = async (keyOfWait: string) => {
    if (!sequenceMasterId) return;
    
    const api = new SequenceStepMasterApiService();
    const { data: fetchedSequenceStepMasters } = await doActionWithWait<AxiosResponse<SequenceStepMaster[]>>(keyOfWait, async () => {
      return await api.getSequenceStepMasters({
        request: {
          sequenceMasterId,
        },
      });
    });

    sequenceStepMasters.value = fetchedSequenceStepMasters;
  };

  return {
    sequenceStepMasters: readonly(sequenceStepMasters),
    fetchSequenceStepMasters,
  };
};

const getMailAggregates = (step: SequenceStepMaster | TFormSequenceStep | null): MailAggregates | null => {
  if (step == null) return null;
  return 'mailAggregates' in step ? step.mailAggregates : null;
};
const getCallAggregates = (step: SequenceStepMaster | TFormSequenceStep | null): CallAggregates | null => {
  if (step == null) return null;
  return 'callAggregates' in step ? step.callAggregates : null;
};

const useSequenceStepMasterData = (getSequenceStepMasters: () => SequenceStepMaster[]) => {
  const sequenceStepData = computed<TFormSequenceStep[]>(() =>
    getSequenceStepMasters().map((step) => {
      const { id, sortOrder, dateInterval, reservedTime, actionTypeId, defaultActionTypeId, priority, deletable } = step;
      return {
        id,
        sortOrder,
        dateInterval,
        reservedTime,
        actionType: makeNextActionType(defaultActionTypeId, actionTypeId),
        priority,
        deletable,
        mailAggregates: getMailAggregates(step),
        callAggregates: getCallAggregates(step),
        ...getActionDetail(step),
      };
    }),
  );

  return {
    sequenceStepData,
  };
};

export {
  useSequenceStepMasters,
  useSequenceStepMasterData,
  getMailAggregates,
  getCallAggregates,
};
