<template>
  <BContentBox
    class="pb-800"
  >
    <template #content>
      <div
        v-show="success != ''"
        class="notice success"
      >
        <span>{{ success }}</span>
        <BBtn
          text
          position="right"
          class="close"
        >
          <BIcon
            class="px-200"
            size="small"
            type="white"
            @click="success = ''"
          >
            close
          </BIcon>
        </BBtn>
      </div>
      <div
        v-show="error != ''"
        class="notice error"
      >
        <span>{{ error }}</span>
        <BBtn
          text
          position="right"
          class="close"
        >
          <BIcon
            class="px-200"
            size="small"
            type="white"
            @click="error = ''"
          >
            close
          </BIcon>
        </BBtn>
      </div>
      <div
        v-loading="salesforceSettingLoading"
        class="content"
      >
        <BLayout
          class="pl-600 pb-200 header"
          justify-center
          align-center
        >
          <BListItem
            class="column-content non-border"
          >
            <span>{{ $t('import.salesforce.salesforce_project') }}</span>
          </BListItem>
          <div class="icon" />
          <BListItem
            class="column-content non-border"
          >
            <span>{{ $t('import.salesforce.bales_cloud_project') }}</span>
          </BListItem>
        </BLayout>
        <BLayout
          v-for="(value, index) in mapping"
          :key="`${index}-${changeBoxCounter}`"
          class="pl-600 pb-200"
          justify-center
          align-center
        >
          <BListItem>
            <BSelect
              v-model="mapping[index]['id']"
              filterable
              clearable
              class="select-column"
              :items="sfUsers"
              item-text="name"
              item-value="id"
              fit
              @clear="() => reset(value['id'])"
            >
              <template #optionTemplate="{ item }">
                <span
                  class="b-select"
                  :class="{'b-selected': item['id'] == value['id']}"
                >
                  {{ item['name'] }}
                </span>
              </template>
            </BSelect>
          </BListItem>
          <div class="icon">
            <BIcon
              class="icon-sync"
              size="b-icon-medium"
            >
              swap_horiz
            </BIcon>
          </div>
          <BListItem>
            <div
              class="column-content non-border bc-label"
            >
              {{ value['name'] }}
            </div>
          </BListItem>
        </BLayout>
      </div>
      <div class="fotter">
        <div
          class="btn-back"
          @click="backAction"
        >
          {{ $t("sfObjectMappings.back") }}
        </div>
        <div
          class="btn-save"
          @click="upsertMapping"
        >
          {{ $t("sfObjectMappings.save") }}
        </div>
      </div>
    </template>
  </BContentBox>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { SfUserMappingService } from '@/api/user/resources/salesforce/sf_user_mapping';
import { i18nGlobal as i18n } from '@/i18n';

const emit = defineEmits(['back']);
const api = new SfUserMappingService();
const mapping = ref([]);
const bcUsers = ref([]);
const sfUsers = ref([]);
const salesforceSettingLoading = ref(true);
const success = ref('');
const error = ref('');
// v-forで選択したときに、同一のkeyの場合に書き換わらないケースががあるため
const changeBoxCounter = ref(0); 

const backAction = () => {
  emit('back');
};

const reset = (sfId: string) => {
  for (const index in mapping.value) {
    if (mapping.value[index].id === sfId) {
      mapping.value[index].id = '';
    }
  }
  // 変更が反映されないことがあるため、v-forのkeyを更新
  changeBoxCounter.value++;
};

const upsertMapping = async () => {
  salesforceSettingLoading.value = true;
  api.upsertSfUserMappingBody(
    {
      upsertSfUserMappingBodyRequest: {
        mapping: JSON.stringify(mapping.value),
      },
    },
  ).then(() => {
    success.value = i18n.t('general.success.to', { action: i18n.t('general.update') });
  }).catch((e) => {
    if (e.response.data.errorMessage) {
      error.value = i18n.t('general.fail.to', { action: `${e.response.data.errorMessage}${i18n.t('general.save.text')}` });
    } else {
      error.value = i18n.t('general.fail.to', { action: i18n.t('general.update') });
    }
  });
  salesforceSettingLoading.value = false;
};

onMounted(async () => {
  const result = await api.getSfUserMappings({});
  mapping.value = result.data.sfUserMapping;
  bcUsers.value = result.data.bcUsers;
  sfUsers.value = result.data.sfUsers;
  salesforceSettingLoading.value = false;
});
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.object-name {
  font-weight: bold;
}

.column-content {
  width: 100%;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #E3E3E3;
}

.bc-label {
  overflow-y: scroll;
}

.select-column {
  width: 100%;
}

.non-border {
  border-width: 0;
}

.icon {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  font-size: 32px !important;
  color: $concrete-dark !important;
  padding: 0 12px;

  .icon-sync {
    color: $concrete-dark !important;
  }
}

.hr {
  border-bottom: 1px solid $bdcolor-base;
  margin-bottom: 8px;
}

.fotter {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 310px);
  height: 63px;
  background-color: #F6F6F6;;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-back {
  color: #231815;
  font-size: 18px;
  margin: 0 12px;
  padding: 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-save {
  width: 101px;
  height: 37px;
  border-radius: 4px;
  background: #018485;
  margin: 0 12px;
  color: #FFF;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.notice {
  position: relative;
  width: 100%;
  height: 60px;
  padding: 12px 0;
  margin: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
}

.close {
  position: absolute;
  right: 0;
}

.success {
  background-color: $basecolor-success;
}

.error {
  background-color: $basecolor-error;
}

.header {
  color: $concrete-dark;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.target-object-name {
  font-weight: bold;
}

.check-area {
  width: 80px !important;
  margin: 0 20px;
  flex-shrink: 0;
  text-align: center;
}

.radio-priority-area {
  width: 60px !important;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  text-align: center;
}

.check-box {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;

  > .check {
    color: $textcolor-white;
  }

  &.check-box-active {
    background-color: $basecolor-success;
  }

  &.check-box-non-active {
    background-color: $concrete-dark;
  }
}

.radio-box {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 50%;

  > .radio {
    color: $textcolor-white;
  }

  &.radio-box-active {
    background-color: $basecolor-success;
  }

  &.radio-box-non-active {
    background-color: $concrete-dark;
  }
}

.b-select {
  color: #231815;
  font-weight: normal;
}
.b-selected {
  color: var(--el-color-primary);
  font-weight: bold;
}
</style>
