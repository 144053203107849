import ApiService from '@/api/api_service';
import {
  SfObjectMappingsApi,
} from '@/api/openapi';

export class SfObjectMappingService extends ApiService {
  api = new SfObjectMappingsApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    // openapiで作ったやつはtrue
    super(true);
  }

  async getSfObjectMappingsKeys() {
    return this.api.getSfObjectMappingsKeys();
  }

  async getSfObjectMappings(args) {
    return this.api.getSfObjectMappings(args);
  }

  async upsertSfObjectMappingBody(args) {
    return this.api.upsertSfObjectMappingBody(args);
  }

  async syncSfObjectMapping() {
    return this.api.syncSfObjectMapping();
  }

  async checkSyncSfObjectMapping() {
    return this.api.checkSyncSfObjectMapping();
  }
}
