import ApiService from '@/api/api_service';
import {
  SfImportApi,
} from '@/api/openapi';

export class SfImportService extends ApiService {
  api = new SfImportApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    // openapiで作ったやつはtrue
    super(true);
  }

  async getSfImportObject(args) {
    return this.api.getSfImportObjectId(args);
  }
  
  async createSfImportObjectId(args) {
    return this.api.createSfImportObjectId(args);
  }
}
