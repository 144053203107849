<template>
  <div
    v-loading="loading"
    class="sisense-dashboard"
  >
    <SisenseContextProvider
      v-if="sisenseAuthChecked"
      url="https://balescloud-dev.sisense.com"
      sso-enabled
    >
      <BContainer class="pt-600">
        <BLayout>
          <BFlex
            :span="16"
          >
            <ElButtonGroup
              class="sisense-dashboard-button-group"
            >
              <ElButton
                :type="indexType(0)"
                class="sisense-dashboard-button"
                @click="handleClickChartIndex(0)"
              >
                {{ $t('sisense.nextAction.countAll') }}
              </ElButton>
              <ElButton
                :type="indexType(1)"
                class="sisense-dashboard-button"
                @click="handleClickChartIndex(1)"
              >
                {{ $t('sisense.nextAction.byType') }}
              </ElButton>
              <ElButton
                :type="indexType(2)"
                class="sisense-dashboard-button"
                @click="handleClickChartIndex(2)"
              >
                {{ $t('sisense.nextAction.byDate') }}
              </ElButton>
            </ElButtonGroup>
            <BCard
              shadow
              class="mt-100"
            >
              <IndicatorChart
                v-show="chartIndex === 0"
                :data-set="nextActionCountAll.dataSet"
                :data-options="nextActionCountAll.dataOptions"
                :style-options="nextActionCountAll.styleOptions"
                :filters="filters"
              />
              <BarChart
                v-show="chartIndex === 1"
                :data-set="nextActionByType.dataSet"
                :data-options="nextActionByType.dataOptions"
                :style-options="nextActionByType.styleOptions"
                :filters="filters"
              />
              <LineChart
                v-show="chartIndex === 2"
                :data-set="nextActionByDate.dataSet"
                :data-options="nextActionByDate.dataOptions"
                :style-options="nextActionByDate.styleOptions"
                :filters="filters"
              />
            </BCard>
          </BFlex>
          <BFlex
            :span="7"
            class="ml-400"
          >
            <MemberFilterTile
              :attribute="memberFilter.attribute"
              :on-change="memberFilter.onChange"
              :data-source="memberFilter.dataSource"
              :title="memberFilter.title"
              :style-options="memberFilter.styleOptions"
              :filter="memberFilter.filter"
            />
          </BFlex>
        </BLayout>
      </BContainer>
    </SisenseContextProvider>
  </div>
</template>

<script setup lang="ts">
import { measureFactory, Filter, filterFactory } from '@sisense/sdk-data';
import {
  SisenseContextProvider,
  IndicatorChart,
  LineChart,
  BarChart,
  MemberFilterTile,
  type BarChartProps,
  type LineChartProps,
  type IndicatorChartProps,
  type MemberFilterTileProps,
} from '@sisense/sdk-ui-vue';

import { ref, computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { SisenseApiService } from '@/api/user/resources/sisense';
import { useSisense } from '@/composable/user/sisense/sisense';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import * as DM from '@/data-models/bc-data-source-V-0-0-1';

const i18n = useI18n();
const { doActionWithWait, wait } = useWait();
const { currentUser, fetchCurrentUser } = useCurrentUser();
const { sisenseAuthChecked, checkSisenseAuth } = useSisense();

const api = new SisenseApiService();
const drawable = ref(false);
const chartIndex = ref(0);

const isDoneFilter = ref<Filter>(filterFactory.equals(DM.NextAction.is_done, 'false'));
const memberFilterValue = ref<Filter>(filterFactory.members(DM.NextAction.owner_name, []));
const filters = computed<Filter[]>(() => {
  return [isDoneFilter.value, memberFilterValue.value];
});

// フィルター
const memberFilter = ref<MemberFilterTileProps>({
  dataSource: DM.DataSource,
  title: i18n.t('sisense.nextAction.owner'),
  attribute: DM.NextAction.owner_name,
  filter: memberFilterValue,
  onChange(filter) {
    memberFilterValue.value = filter;
  },
});

// 次のアクション:総数
const nextActionCountAll = ref<IndicatorChartProps>({
  dataSet: DM.DataSource,
  dataOptions: {
    value: [
      measureFactory.count(DM.NextAction.NextAction_id, i18n.t('sisense.nextAction.count')),
    ],
  },
});

// 次のアクション:タイプ別
const nextActionByType = ref<BarChartProps>({
  dataSet: DM.DataSource,
  dataOptions: {
    category: [DM.NextAction.action_type_name],
    value: [{
      column: measureFactory.count(DM.NextAction.NextAction_id, i18n.t('sisense.nextAction.count')),
      sortType: 'sortDesc',
    }],
    breakBy: [],
  },
  styleOptions: {
    width: 480,
    height: 400,
  },
});

// 次のアクション:日毎
const nextActionByDate = ref<LineChartProps>({
  dataSet: DM.DataSource,
  dataOptions: {
    category: [DM.NextAction.reserved_at_jst.Days],
    value: [
      measureFactory.count(DM.NextAction.NextAction_id, i18n.t('sisense.nextAction.count')),
    ],
    breakBy: [],
  },
  styleOptions: {
    width: 480,
    height: 400,
  },
});

const loading = computed(() => {
  return wait.is('checkSisenseAccount');
});

onBeforeMount(async () => {
  // BCとSisenseのアカウントが合致することをチェック
  await fetchCurrentUser();
  await checkSisenseAccount();
  await checkSisenseAuth(currentUser.value);
  initializeFilter();
});

const indexType = (index: number) => {
  return chartIndex.value === index ? 'primary': 'default';
};

const checkSisenseAccount = async () => {
  await doActionWithWait('checkSisenseAccount', async () => {
    const loggedin = await api.loggedin();
    if (loggedin.ok) {
      const loggedinJson = await loggedin.json();
      // NOTE: BCアカウントとSisenseアカウントが同じでない場合はSisenseをログアウト
      if (loggedinJson.email !== currentUser.value.email) {
        await api.logout();
      }
    }
    drawable.value = true;
  });
};

const initializeFilter = () => {
  memberFilterValue.value = filterFactory.members(DM.NextAction.owner_name, [currentUser.value.name]);
};

const handleClickChartIndex = (index: number) => {
  chartIndex.value = index;
};
</script>

<style lang="scss" scoped>
.sisense-dashboard {
  margin-top: 50px;
  @include m-fixed-height($page-height);

  .sisense-dashboard-button-group {
    display: flex;
    width: 100%;

    .el-button {
      background-color: var(--el-button-bg-color);
    }
  }

  .sisense-dashboard-button {
    flex: 1;
  }
}
</style>
