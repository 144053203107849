<template>
  <CommonTask
    v-model:content="content"
    :owner="nextAction.owner"
    :title="nextAction.name"
    :date-time="nextAction.reservedAt"
    :is-sequence="isSequenceAction"
    :is-deletable="isDeletable"
    :is-content-editable="isContentEditable"
    :link-to-text="$t('general.confirm.do')"
  >
    <template #action>
      <BTooltip
        top
        :content="$t('callTarget.header.callResult')"
        :disabled="callResultFormDisable"
      >
        <BBtn
          size="mini"
          fab
          :disabled="callResultFormDisable"
          @click.stop="handleCallResultFormOpenClick"
        >
          <BIcon size="mini">
            check
          </BIcon>
        </BBtn>
      </BTooltip>
    </template>
    <template #status>
      <BPriorityPreview
        :priority="nextAction.priority"
        size="micro"
      />
    </template>
    <template #optionalActions>
      <BTooltip
        top
        :content="$t('callTarget.errors.salesforceUnableToLinkUser.title')"
        :disabled="canOpenCallResultFrom"
      >
        <ElDropdown trigger="click">
          <BBtn
            class="click-to-call-toggle"
            type="default"
            size="mini"
          >
            {{ $t('general.callAction.do') }}
            <ElIcon class="el-icon--right">
              <ArrowDown />
            </ElIcon>
          </BBtn>
          <template #dropdown>
            <BTooltip
              top
              :content="$t('callTarget.errors.salesforceUnableToLinkUser.title')"
              :disabled="canOpenCallResultFrom"
            >
              <ElDropdownMenu>
                <ElDropdownItem
                  :disabled="!callTarget.callTargetInfo.personalTel || !canOpenCallResultFrom"
                >
                  <ElTooltip
                    :disabled="!callTarget.callTargetInfo.personalTel"
                    :content="callTarget.callTargetInfo.personalTel"
                    placement="right"
                    effect="light"
                  >
                    <div class="click-to-call">
                      <div class="click-to-call-text">
                        {{ $t('general.callAction.to', { target: $t('callTarget.target') }) }}
                      </div>
                      <CallButtonContainer
                        class="click-to-call-button"
                        phone-number-key="personal_tel"
                        :phone-number="callTarget.callTargetInfo.personalTel"
                        :call-result-next-action-id="nextAction.id"
                      />
                    </div>
                  </ElTooltip>
                </ElDropdownItem>
                <ElDropdownItem
                  :disabled="!callTarget.companyInfo.companyTel1 || !canOpenCallResultFrom"
                >
                  <ElTooltip
                    :disabled="!callTarget.companyInfo.companyTel1"
                    :content="callTarget.companyInfo.companyTel1"
                    placement="right"
                    effect="light"
                  >
                    <div class="click-to-call">
                      <div class="click-to-call-text">
                        {{ $t('general.callAction.to', { target: $t('callTarget.company') }) }}
                      </div>
                      <CallButtonContainer
                        class="click-to-call-button"
                        phone-number-key="company_tel_1"
                        :phone-number="callTarget.companyInfo.companyTel1"
                        :call-result-next-action-id="nextAction.id"
                      />
                    </div>
                  </ElTooltip>
                </ElDropdownItem>
              </ElDropdownMenu>
            </BTooltip>
          </template>
        </ElDropdown>
      </BTooltip>
    </template>
  </CommonTask>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { CallTarget, NextAction } from '@/api/openapi';
import { useSfSync } from '@/composable/sf-feature';
import { useNextActionComputed } from '@/composable/user/nextAction/next-action';
import { key } from '@/store/index';
import CallButtonContainer from '../detail/CallButtonContainer.vue';
import CommonTask from './CommonTask.vue';

type TProps = {
  nextAction: NextAction;
  callTarget: CallTarget;
};
const props = defineProps<TProps>();
const content = defineModel<string>('content');

watch(() => props.nextAction.content, (newVal) => {
  content.value = newVal;
}, { immediate: true });

const store = useStore(key);
const { canOpenCallResultFrom, validateCallResultSyncState } = useSfSync(() => props.callTarget);
const { isSequenceAction } = useNextActionComputed(props.nextAction);

const isDeletable = computed(() => !isSequenceAction.value);
const isContentEditable = computed(() => !isSequenceAction.value);
const callResultFormDisable = computed(() => store.getters['userUi/targetCallResultFormVisible']);

const handleCallResultFormOpenClick = async () => {
  const valid = await validateCallResultSyncState();
  if (!valid) return;

  store.dispatch('userUi/setTargetCallResultFormLogValueszAction', {
    nextActionId: props.nextAction.id,
  });
  store.dispatch('userUi/setTargetHeaderMoveButtonDisabledAction', true);
  store.dispatch('userUi/setTargetCallResultFormVisibleAction', true);
};
</script>

<style lang="scss" scoped>
.click-to-call-toggle {
  padding: 0 $basespace-100;
  .el-icon {
    padding-left: $basespace-50;
  }
}
.click-to-call {
  display: flex;
  width: 100%;
  .click-to-call-text {
    padding-right: $basespace-200;
    font-size: $fontsize-100;
    line-height: 28px; // NOTE: ボタンの高さと同じにする
  }
}
:deep(.click-to-call-button) {
  display: block;
  margin-left: auto;
  .b-icon {
    margin: 0;
  }
}
</style>
