<template>
  <div
    v-loading="loading"
    class="frame"
    :class="{ loading: loading }"
  >
    <iframe
      v-show="!loading"
      id="sisense-iframe"
      class="content"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useSisense } from '@/composable/user/sisense/sisense';
import { useCurrentUser } from '@/composable/user/user/users';

export default defineComponent({
  data() {
    return {
      loading: true,
      sisenseFrame: {},
    };
  },
  created(){
    const embed = window['sisense.embed'];
    this.sisenseFrame = embed.SisenseFrame;
  },
  async mounted() {
    const { currentUser, fetchCurrentUser } = useCurrentUser();
    const { checkSisenseAuth, sisenseAuthed } = useSisense();

    // BCとSisenseのアカウントが合致することをチェック
    await fetchCurrentUser();
    await checkSisenseAuth(currentUser.value);

    // Sisense未認証ならSSOへリダイレクトしてから戻って来る
    const authed = await sisenseAuthed();
    if (!authed) {
      this.$router.push({ name: 'SisenseSSO', query: { return_to: `https://${location.hostname}/app/analytics` } });
      return;
    }

    const frame = new this.sisenseFrame({
      url: import.meta.env.VITE_SISENSE_URL,
      dashboard: import.meta.env.VITE_SISENSE_DASHBOARD_ID,
      settings: {
        showLeftPane: true,
        showToolbar: true,
        showRightPane: true,
      },
      element: document.getElementById('sisense-iframe'),
    });

    // Sisenseのバグでrender methodでloading解除するとwidiget操作のボタンが消えるため一時的にsetTimeoutで解除する
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
});
</script>

<style lang="scss" scoped>
.frame {
  width: calc(100% - #{$sidebar-width});
  min-width: calc(100% - #{$sidebar-width});
  height: calc(100vh - 50px);
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  &.loading {
    margin-top: 50px;
  }
  .content {
    margin-top: 45px;
    margin-left: -2px;
    width: calc(100% - #{$sidebar-width});
    min-width: calc(100% - #{$sidebar-width});
    height: calc(100vh - 50px);
    min-height: calc(100vh - 50px);
    max-height: calc(100vh - 50px);
  }

}
</style>
