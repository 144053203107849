<template>
  <BModal
    :modal-name="modalName"
    full
  >
    <BHeightAdjuster>
      <BLayout
        align-center
        class="wrap-header"
      >
        <BModalHeader
          :title="title"
          center
          @modal-close="close"
        />
      </BLayout>
      <BModalBody>
        <BLayout
          justify-center
          align-center
          is-fit
        >
          <div class="modal-message mb-400">
            {{ $t('import.salesforce.modalSingleImportMessage') }}
          </div>
        </BLayout>
        <BContainer>
          <BListItem class="mb-600">
            <template #header>
              <span>{{ $t('import.salesforce.formSfIdLabel') }}</span>
            </template>
            <BInput
              v-model="sfId"
              v-model:valid="sfIdValid"
              :rules="[requiredRule]"
              :max-length="20"
              :min-length="12"
              @keypress-enter="searchSfIde"
            >
              <template #prefix>
                <BElementIcon
                  name="Search"
                  type="gray"
                />
              </template>
            </BInput>
          </BListItem>
        </BContainer>
      </BModalBody>
      <BModalFooter class="modal-footer">
        <BLayout
          class="mt-100"
          justify-center
          align-center
        >
          <BBtn
            type="primary"
            class="btn-import"
            @click="save"
          >
            {{ $t('general.save.text') }}
          </BBtn>
        </BLayout>
      </BModalFooter>
    </BHeightAdjuster>
  </BModal>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import { SfImportService } from '@/api/user/resources/salesforce/sf_import';
import { useInputValidations } from '@/composable/input-validations';
import { useAlert } from '@/composable/message-dialog';
import { i18nGlobal as i18n } from '@/i18n';
import { KEY_OF_MODAL } from '@/injection-keys';

const emit = defineEmits(['back']);

const api = new SfImportService();

const { alert } = useAlert();

const { requiredRule } = useInputValidations();

const title = `${i18n.t('import.salesforce.title')}${i18n.t('general.parentheses', { item: i18n.t('leadList.import.type.sfId') })}`;

const modalName = ref('SalesforceSingleImportModal');
const modal = inject(KEY_OF_MODAL);
const close = () => {
  modal.hide('SalesforceSingleImportModal');
};

const sfId = ref('');
const sfIdValid = () => {
  return false;
};

const searchSfIde = async () => {
  try {
    const result = await api.getSfImportObject({ sfId: sfId.value });
    if (result.data.sfId != null) {
      return [result.data.sfObjectName, result.data.sfId];
    }
  } catch (e) {
    await alert({
      title: i18n.t('general.error'),
      message: i18n.t('sfRelatedLead.syncErrorMessages.invalidSaleforceId', { ids: sfId.value }),
    });
  }
};
const save = async () => {
  const [sfObjectName, resultSfId] = await searchSfIde();
  if (sfObjectName == null) return;

  const result = await api.createSfImportObjectId({
    createSfImportObjectIdRequest: { sfId: resultSfId, sfObjectName },
  });
  if (result.data.errorMessage.length > 0) {
    await alert({
      title: i18n.t('general.error'),
      message: result.data.errorMessage,
    });
    return;
  }
  console.log(result.data.callTarget);
  window.location.href = `?callTargetId=${result.data.callTarget.id}`;
};
</script>

<style lang="scss" scoped>
.modal-message {
  text-align: left;
  color: $textcolor-light;
  background-color: $bgcolor-base;
  padding: $basespace-300 $basespace-200;
  border-radius: 4px;
}
</style>
