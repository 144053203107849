<template>
  <div :class="attributes">
    <BToast />
    <RouterView />
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    attributes () {
      return {
        'dark-mode': import.meta.env.VITE_ENABLE_DARK_MODE,
      };
    },
  },
  created () {
    if (import.meta.env.VITE_ELASTIC_APM_SECRET_TOKEN) {
      this.span = this.$apm.startSpan();
    }
  },
  mounted () {
    if (import.meta.env.VITE_ELASTIC_APM_SECRET_TOKEN) {
      this.span && this.span.end();
    }
  },
};
</script>
