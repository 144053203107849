import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  SisenseApi,
  GetSisenseSSOToken200Response,
} from '@/api/openapi';

export class SisenseSSOApiService extends ApiService {
  api = new SisenseApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getSisenseSSOToken(
    apiArguments?: ApiArguments<undefined>,
  ): Promise<AxiosResponse<GetSisenseSSOToken200Response>> {
    return this.checkResponse<GetSisenseSSOToken200Response>(
      this.api.getSisenseSSOToken(apiArguments?.options),
      apiArguments?.errorHandlers,
    );
  }
}

export class SisenseApiService {
  private baseUrl = `${import.meta.env.VITE_SISENSE_URL}`;

  async isauth() {
    const isauthUrl = `${this.baseUrl}/api/auth/isauth`;
    return await fetch(isauthUrl, { credentials: 'include' });
  }

  async logout() {
    const logoutUrl = `${this.baseUrl}/api/auth/logout`;
    return await fetch(logoutUrl, { credentials: 'include' });
  }

  async loggedin() {
    const loggedinUrl = `${this.baseUrl}/api/users/loggedin`;
    return await fetch(loggedinUrl, { credentials: 'include' });
  }
}
