import { useI18n } from 'vue-i18n';
import { TAction, TActionWithDisabled, TFormSequenceStep } from '@/components/organisms/user/general/sequence/types';
import { isAutomatic, isCallActionType, isDirectMailActionType, isDirectMailStepExecuted } from '@/composable/user/sequence/post-put-body';

const useSequenceStepMasterActions = () => {
  const i18n = useI18n();
  const actions: TAction[] = [
    { label: i18n.t('general.edit.text'), event: 'click:edit' },
    { label: i18n.t('general.moveUp'), event: 'click:moveUp' },
    { label: i18n.t('general.moveDown'), event: 'click:moveDown' },
    { label: i18n.t('general.delete.text'), event: 'click:delete' },
  ];

  const disableContents = (steps: TFormSequenceStep[], step: TFormSequenceStep, action: TAction) => {
    if (action.event === 'click:moveUp') {
      return { disabled: step.sortOrder === 0 };
    } else if (action.event === 'click:moveDown') {
      return { disabled: step.sortOrder === steps.length - 1 };
    } else if (action.event === 'click:delete') {
      return step.deletable
        ? { disabled: false }
        : { disabled: true, disabledContent: i18n.t('general.alreadyUsed') };
    }
    return { disabled: false };
  };

  const getStepsActions = (steps: TFormSequenceStep[]): TActionWithDisabled[][] =>
    steps.map(step =>
      actions.map(action => ({
        ...action,
        ...disableContents(steps, step, action),
      })),
    );

  return {
    getStepsActions,
  };
};

const useSequenceStepMasterApplyActions = () => {
  const i18n = useI18n();
  const ACTIONS: Record<string, TAction> = {
    edit: { label: i18n.t('general.edit.text'), event: 'click:edit' },
    sendImmediately: { label: i18n.t('general.sendImmediately'), event: 'click:sendImmediately' },
    startImmediately: { label: i18n.t('general.startImmediately'), event: 'click:startImmediately' },
    cancelSendImmediately: { label: i18n.t('general.cancelSendImmediately'), event: 'click:cancelSendImmediately' },
    cancelStartImmediately: { label: i18n.t('general.cancelStartImmediately'), event: 'click:cancelStartImmediately' },
  };

  const makeActions = (step: TFormSequenceStep): TAction[] => {
    const editActions = [ACTIONS.edit];
    if (step.sortOrder > 0) return editActions;

    const isDirectMail = isDirectMailActionType(step.actionType);
    // 手動メールの場合は今すぐ配信表示なし
    if (isDirectMail && !isAutomatic(step)) return editActions;

    let actionImmediately;
    if (step.immediately) {
      if (isDirectMail) {
        actionImmediately = ACTIONS.cancelSendImmediately;
      } else {
        actionImmediately = ACTIONS.cancelStartImmediately;
      }
    } else {
      if (isDirectMailActionType(step.actionType)) {
        actionImmediately = ACTIONS.sendImmediately;
      } else {
        actionImmediately = ACTIONS.startImmediately;
      }
    }
    return [...editActions, actionImmediately];
  };

  const getStepsActions = (steps: TFormSequenceStep[]): TActionWithDisabled[][] =>
    steps.map(step =>
      makeActions(step)
        .map(action => ({
          ...action,
          disabled: false,
        })),
    );

  return {
    getStepsActions,
  };
};

const useSequenceStepInstanceActions = () => {
  const i18n = useI18n();
  const ACTIONS: Record<string, TAction> = {
    detail: { label: i18n.t('sequence.step.detail'), event: 'click:detail' },
    edit: { label: i18n.t('general.edit.text'), event: 'click:edit' },
    skip: { label: i18n.t('general.skip'), event: 'click:skip' },
    sendImmediately: { label: i18n.t('general.sendImmediately'), event: 'click:sendImmediately' },
    startImmediately: { label: i18n.t('general.startImmediately'), event: 'click:startImmediately' },
    callResultDetail: { label: i18n.t('sequence.step.callResult.detail'), event: 'click:callResultDetail' },
  };

  const makeActions = (step: TFormSequenceStep): TAction[] => {
    // NOTE: 通話結果詳細はスキップした場合は表示しない
    const previewActions = isCallActionType(step.actionType) && step.status !== 'skipped'
      ? [ACTIONS.detail, ACTIONS.callResultDetail]
      : [ACTIONS.detail];
    if (!step.sequenceEditable) return previewActions;

    if (step.status === 'running' && !isDirectMailStepExecuted(step)) {
      // 現在のステップ：running（個別メールの場合は実行日時前(送信前)のみ）
      const runningActions = [ACTIONS.edit, ACTIONS.skip];
      const isDirectMail = isDirectMailActionType(step.actionType);
      // NOTE: 手動メールの場合は今すぐ配信表示なし
      if (isDirectMail && !isAutomatic(step)) return runningActions;
      const actionImmediately = isDirectMail
        ? ACTIONS.sendImmediately
        : ACTIONS.startImmediately;
      return [...runningActions, actionImmediately];
    } else if (step.status === 'not_running') {
      return [ACTIONS.edit];
    } else {
      // running(executed), done, skipped, failed, sending
      return previewActions;
    }
  };

  const getStepsActions = (steps: TFormSequenceStep[]): TActionWithDisabled[][] =>
    steps.map(step =>
      makeActions(step)
        .map(action => ({
          ...action,
          disabled: false,
        })),
    );

  return {
    getStepsActions,
  };
};

export {
  useSequenceStepMasterActions,
  useSequenceStepMasterApplyActions,
  useSequenceStepInstanceActions,
};
