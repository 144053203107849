import { readonly, ref } from 'vue';
import { User } from '@/api/openapi';
import { SisenseApiService } from '@/api/user/resources/sisense';

const useSisense = () => {
  const api = new SisenseApiService();
  const sisenseAuthChecked = ref(false);

  const checkSisenseAuth = async (currentUser: User) => {
    const loggedin = await api.loggedin();
    if (loggedin.ok) {
      const loggedinJson = await loggedin.json();
      // NOTE: BCアカウントとSisenseアカウントが同じでない場合はSisenseをログアウト
      if (loggedinJson.email !== currentUser.email) {
        await api.logout();
      }
    }
    sisenseAuthChecked.value = true;
  };

  const sisenseAuthed = async () => {
    const isauth = await api.isauth();
    const isauthJson = await isauth.json();
    return isauthJson.isAuthenticated;
  };

  return {
    sisenseAuthChecked: readonly(sisenseAuthChecked),
    checkSisenseAuth,
    sisenseAuthed,
  };
};

export {
  useSisense,
};
