import type { Dimension, DateDimension, Attribute, DataSourceInfo } from '@sisense/sdk-data';

import { createAttribute, createDateDimension, createDimension } from '@sisense/sdk-data';

const dataSourceName = process.env.NODE_ENV === 'production' ? 'BC_PRODUCTION_DATA_SOURCE_V_0_0_1' : 'BC_STAGING_DATA_SOURCE_V_0_0_1';

export const DataSource: DataSourceInfo = {
  title: dataSourceName,
  type: 'elasticube',
};

interface CallResultDimension extends Dimension {
  call_result_type_name: Attribute;
  call_target_id: Attribute;
  connected_text: Attribute;
  connected_to_text: Attribute;
  created_by: Attribute;
  CallResult_id: Attribute;
  next_action_id: Attribute;
  first_set_up_at_jst: DateDimension;
  started_at_jst: DateDimension;
}
export const CallResult = createDimension({
  name: 'CallResult',
  call_result_type_name: createAttribute({
    name: 'call_result_type_name',
    type: 'text-attribute',
    expression: '[コール結果.コール結果タイプ]',
    dataSource: { title: dataSourceName, live: false },
  }),
  call_target_id: createAttribute({
    name: 'call_target_id',
    type: 'numeric-attribute',
    expression: '[コール結果.コール実施リードID]',
    dataSource: { title: dataSourceName, live: false },
  }),
  connected_text: createAttribute({
    name: 'connected_text',
    type: 'text-attribute',
    expression: '[コール結果.接続状況]',
    dataSource: { title: dataSourceName, live: false },
  }),
  connected_to_text: createAttribute({
    name: 'connected_to_text',
    type: 'text-attribute',
    expression: '[コール結果.接続先]',
    dataSource: { title: dataSourceName, live: false },
  }),
  created_by: createAttribute({
    name: 'created_by',
    type: 'text-attribute',
    expression: '[コール結果.コール結果作成者]',
    dataSource: { title: dataSourceName, live: false },
  }),
  CallResult_id: createAttribute({
    name: '[CallResult.id]',
    type: 'numeric-attribute',
    expression: '[コール結果.コール結果ID]',
    dataSource: { title: dataSourceName, live: false },
  }),
  next_action_id: createAttribute({
    name: 'next_action_id',
    type: 'numeric-attribute',
    expression: '[コール結果.next_action_id]',
    dataSource: { title: dataSourceName, live: false },
  }),
  first_set_up_at_jst: createDateDimension({
    name: 'first_set_up_at_jst',
    expression: '[コール結果.コール結果作成日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
  started_at_jst: createDateDimension({
    name: 'started_at_jst',
    expression: '[コール結果.コール結果開始日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
}) as CallResultDimension;

export const CustomField = createDimension({
  name: 'CustomField',
  CustomField_name: createAttribute({
    name: '[CustomField.name]',
    type: 'text-attribute',
    expression: '[カスタム項目.カスタム項目名]',
    dataSource: { title: dataSourceName, live: false },
  }),
});

interface CustomFieldValueDimension extends Dimension {
  actual_filed_option_value: Attribute;
  field_number_value: Attribute;
  field_text_value: Attribute;
  filed_datetime_value: DateDimension;
}
export const CustomFieldValue = createDimension({
  name: 'CustomFieldValue',
  actual_filed_option_value: createAttribute({
    name: 'actual_filed_option_value',
    type: 'text-attribute',
    expression: '[カスタム項目値.カスタム項目値：選択肢型]',
    dataSource: { title: dataSourceName, live: false },
  }),
  field_number_value: createAttribute({
    name: 'field_number_value',
    type: 'numeric-attribute',
    expression: '[カスタム項目値.カスタム項目値：数値型]',
    dataSource: { title: dataSourceName, live: false },
  }),
  field_text_value: createAttribute({
    name: 'field_text_value',
    type: 'text-attribute',
    expression: '[カスタム項目値.カスタム項目値：テキスト型]',
    dataSource: { title: dataSourceName, live: false },
  }),
  filed_datetime_value: createDateDimension({
    name: 'filed_datetime_value',
    expression: '[カスタム項目値.カスタム項目値：日時型 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
}) as CustomFieldValueDimension;

interface DIrectMailDimension extends Dimension {
  DIrectMail_id: Attribute;
  sent_subject: Attribute;
  sent_user_name: Attribute;
  sent_at_jst: DateDimension;
}
export const DIrectMail = createDimension({
  name: 'DIrectMail',
  DIrectMail_id: createAttribute({
    name: '[DIrectMail.id]',
    type: 'numeric-attribute',
    expression: '[個別メール.個別メールID]',
    dataSource: { title: dataSourceName, live: false },
  }),
  sent_subject: createAttribute({
    name: 'sent_subject',
    type: 'text-attribute',
    expression: '[個別メール.メール件名]',
    dataSource: { title: dataSourceName, live: false },
  }),
  sent_user_name: createAttribute({
    name: 'sent_user_name',
    type: 'text-attribute',
    expression: '[個別メール.個別メール作成者]',
    dataSource: { title: dataSourceName, live: false },
  }),
  sent_at_jst: createDateDimension({
    name: 'sent_at_jst',
    expression: '[個別メール.個別メール送信日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
}) as DIrectMailDimension;

export const FreeTag = createDimension({
  name: 'FreeTag',
  text: createAttribute({
    name: 'text',
    type: 'text-attribute',
    expression: '[タグ.タグ名]',
    dataSource: { title: dataSourceName, live: false },
  }),
});

export const LastLeadStage = createDimension({
  name: 'LastLeadStage',
  LastLeadStage_name: createAttribute({
    name: '[LastLeadStage.name]',
    type: 'text-attribute',
    expression: '[最終リードステージ.リードステージ名]',
    dataSource: { title: dataSourceName, live: false },
  }),
});

interface LeadDimension extends Dimension {
  business_category: Attribute;
  company_address: Attribute;
  company_name: Attribute;
  company_prefecture: Attribute;
  created_by: Attribute;
  department: Attribute;
  has_opportunities: Attribute;
  Lead_id: Attribute;
  number_of_employee: Attribute;
  position: Attribute;
}
export const Lead = createDimension({
  name: 'Lead',
  business_category: createAttribute({
    name: 'business_category',
    type: 'text-attribute',
    expression: '[リード情報.業種]',
    dataSource: { title: dataSourceName, live: false },
  }),
  company_address: createAttribute({
    name: 'company_address',
    type: 'text-attribute',
    expression: '[リード情報.住所]',
    dataSource: { title: dataSourceName, live: false },
  }),
  company_name: createAttribute({
    name: 'company_name',
    type: 'text-attribute',
    expression: '[リード情報.会社名]',
    dataSource: { title: dataSourceName, live: false },
  }),
  company_prefecture: createAttribute({
    name: 'company_prefecture',
    type: 'text-attribute',
    expression: '[リード情報.都道府県]',
    dataSource: { title: dataSourceName, live: false },
  }),
  created_by: createAttribute({
    name: 'created_by',
    type: 'text-attribute',
    expression: '[リード情報.リード所有者]',
    dataSource: { title: dataSourceName, live: false },
  }),
  department: createAttribute({
    name: 'department',
    type: 'text-attribute',
    expression: '[リード情報.部署]',
    dataSource: { title: dataSourceName, live: false },
  }),
  has_opportunities: createAttribute({
    name: 'has_opportunities',
    type: 'text-attribute',
    expression: '[リード情報.商談化済みリード]',
    dataSource: { title: dataSourceName, live: false },
  }),
  Lead_id: createAttribute({
    name: '[Lead.id]',
    type: 'numeric-attribute',
    expression: '[リード情報.リードID]',
    dataSource: { title: dataSourceName, live: false },
  }),
  number_of_employee: createAttribute({
    name: 'number_of_employee',
    type: 'text-attribute',
    expression: '[リード情報.従業員規模]',
    dataSource: { title: dataSourceName, live: false },
  }),
  position: createAttribute({
    name: 'position',
    type: 'text-attribute',
    expression: '[リード情報.役職]',
    dataSource: { title: dataSourceName, live: false },
  }),
}) as LeadDimension;

export const LeadLeadSource = createDimension({
  name: 'LeadLeadSource',
  registered_at_jst: createDateDimension({
    name: 'registered_at_jst',
    expression: '[リード_リードソース.リード流入日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
});

export const LeadSource = createDimension({
  name: 'LeadSource',
  LeadSource_name: createAttribute({
    name: '[LeadSource.name]',
    type: 'text-attribute',
    expression: '[リードソース.リードソース名]',
    dataSource: { title: dataSourceName, live: false },
  }),
});

export const LeadTag = createDimension({
  name: 'LeadTag',
  related_at_jst: createDateDimension({
    name: 'related_at_jst',
    expression: '[リード_タグ.タグ紐付け日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
});

interface MailCampaignDimension extends Dimension {
  campaign_name: Attribute;
  MailCampaign_id: Attribute;
  sent_at_jst: DateDimension;
}
export const MailCampaign = createDimension({
  name: 'MailCampaign',
  campaign_name: createAttribute({
    name: 'campaign_name',
    type: 'text-attribute',
    expression: '[メール配信キャンペーン.キャンペーン名]',
    dataSource: { title: dataSourceName, live: false },
  }),
  MailCampaign_id: createAttribute({
    name: '[MailCampaign.id]',
    type: 'numeric-attribute',
    expression: '[メール配信キャンペーン.メール配信キャンペーンID]',
    dataSource: { title: dataSourceName, live: false },
  }),
  sent_at_jst: createDateDimension({
    name: 'sent_at_jst',
    expression: '[メール配信キャンペーン.メール配信日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
}) as MailCampaignDimension;

export const MailDelivery = createDimension({
  name: 'MailDelivery',
  MailDelivery_id: createAttribute({
    name: '[MailDelivery.id]',
    type: 'numeric-attribute',
    expression: '[メール配信キャンペーン.メール配信ID]',
    dataSource: { title: dataSourceName, live: false },
  }),
});

interface MailEventDimension extends Dimension {
  MailEvent_id: Attribute;
  local_event: Attribute;
  url: Attribute;
  timestamp_jst: DateDimension;
}
export const MailEvent = createDimension({
  name: 'MailEvent',
  MailEvent_id: createAttribute({
    name: '[MailEvent.id]',
    type: 'numeric-attribute',
    expression: '[メールイベント.メールイベントID]',
    dataSource: { title: dataSourceName, live: false },
  }),
  local_event: createAttribute({
    name: 'local_event',
    type: 'text-attribute',
    expression: '[メールイベント.メールイベント名]',
    dataSource: { title: dataSourceName, live: false },
  }),
  url: createAttribute({
    name: 'url',
    type: 'text-attribute',
    expression: '[メールイベント.メールイベントURL]',
    dataSource: { title: dataSourceName, live: false },
  }),
  timestamp_jst: createDateDimension({
    name: 'timestamp_jst',
    expression: '[メールイベント.メールイベント発生日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
}) as MailEventDimension;

export const MailUnsubscribe = createDimension({
  name: 'MailUnsubscribe',
  MailUnsubscribe_id: createAttribute({
    name: '[MailUnsubscribe.id]',
    type: 'numeric-attribute',
    expression: '[メール配信停止.メール配信停止ID]',
    dataSource: { title: dataSourceName, live: false },
  }),
});

interface NextActionDimension extends Dimension {
  action_type_name: Attribute;
  done_by_name: Attribute;
  NextAction_id: Attribute;
  is_done: Attribute;
  owner_name: Attribute;
  done_at_jst: DateDimension;
  first_set_up_at_jst: DateDimension;
  reserved_at_jst: DateDimension;
}
export const NextAction = createDimension({
  name: 'NextAction',
  action_type_name: createAttribute({
    name: 'action_type_name',
    type: 'text-attribute',
    expression: '[次のアクション.次のアクションタイプ]',
    dataSource: { title: dataSourceName, live: false },
  }),
  done_by_name: createAttribute({
    name: 'done_by_name',
    type: 'text-attribute',
    expression: '[次のアクション.次のアクション完了日時]',
    dataSource: { title: dataSourceName, live: false },
  }),
  NextAction_id: createAttribute({
    name: '[NextAction.id]',
    type: 'numeric-attribute',
    expression: '[次のアクション.次のアクションID]',
    dataSource: { title: dataSourceName, live: false },
  }),
  is_done: createAttribute({
    name: 'is_done',
    type: 'text-attribute',
    expression: '[次のアクション.完了済み次のアクション]',
    dataSource: { title: dataSourceName, live: false },
  }),
  owner_name: createAttribute({
    name: 'owner_name',
    type: 'text-attribute',
    expression: '[次のアクション.次のアクション所有者]',
    dataSource: { title: dataSourceName, live: false },
  }),
  done_at_jst: createDateDimension({
    name: 'done_at_jst',
    expression: '[次のアクション.次のアクション完了日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
  first_set_up_at_jst: createDateDimension({
    name: 'first_set_up_at_jst',
    expression: '[次のアクション.次のアクション作成日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
  reserved_at_jst: createDateDimension({
    name: 'reserved_at_jst',
    expression: '[次のアクション.次のアクション予定日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
}) as NextActionDimension;

interface OpportunityDimension extends Dimension {
  amount: Attribute;
  created_user_name: Attribute;
  Opportunity_id: Attribute;
  executed_at_jst: DateDimension;
  started_at_jst: DateDimension;
}
export const Opportunity = createDimension({
  name: 'Opportunity',
  amount: createAttribute({
    name: 'amount',
    type: 'numeric-attribute',
    expression: '[商談化情報.商談化金額]',
    dataSource: { title: dataSourceName, live: false },
  }),
  created_user_name: createAttribute({
    name: 'created_user_name',
    type: 'text-attribute',
    expression: '[商談化情報.商談作成者]',
    dataSource: { title: dataSourceName, live: false },
  }),
  Opportunity_id: createAttribute({
    name: '[Opportunity.id]',
    type: 'numeric-attribute',
    expression: '[商談化情報.商談ID]',
    dataSource: { title: dataSourceName, live: false },
  }),
  executed_at_jst: createDateDimension({
    name: 'executed_at_jst',
    expression: '[商談化情報.商談作成日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
  started_at_jst: createDateDimension({
    name: 'started_at_jst',
    expression: '[商談化情報.商談予定日時 (Calendar)]',
    dataSource: { title: dataSourceName, live: false },
  }),
}) as OpportunityDimension;

interface TenantDimension extends Dimension {
  Tenant_id: Attribute;
  sequence_execution_type: Attribute;
}
export const Tenant = createDimension({
  name: 'Tenant',
  Tenant_id: createAttribute({
    name: '[Tenant.id]',
    type: 'numeric-attribute',
    expression: '[テナント情報.テナントID]',
    dataSource: { title: dataSourceName, live: false },
  }),
  sequence_execution_type: createAttribute({
    name: 'sequence_execution_type',
    type: 'numeric-attribute',
    expression: '[テナント情報.sequence_execution_type]',
    dataSource: { title: dataSourceName, live: false },
  }),
}) as TenantDimension;
