<template>
  <div
    class="b-label"
    :class="classes"
  >
    <div
      v-if="$slots.left"
      class="b-label-left"
    >
      <slot name="left" />
    </div>
    <slot />
    <div
      v-if="$slots.right"
      class="b-label-right"
    >
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BLabel',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    round: Boolean,
  },
  computed: {
    classes () {
      return {
        'b-label-default': this.type === 'default',
        'b-label-primary': this.type === 'primary',
        'b-label-warning': this.type === 'warning',
        'b-label-light-green': this.color === 'light-green',
        'b-label-light-blue': this.color === 'light-blue',
        'is-round': this.round,
        'disable': this.disabled,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.b-label {
  display: inline-flex;
  align-items: center;
  height: 28px;
  line-height: 24px;
  font-size: $fontsize-100;
  padding: 0 $basespace-200;

  &.is-round {
    border-radius: 14px;
  }
}

.b-label-default {
  background-color: $bgcolor-base;
}

.b-label-primary {
  color: $textcolor-white;
  background-color: $basecolor-primary;
}

.b-label-warning {
  background-color: $basecolor-warning;
}

.b-label-light-green {
  background-color: $basecolor-success;
}

.b-label-light-blue {
  background-color: rgba($basecolor-cold, 0.1);
}

.b-label-left {
  display: flex;
  align-items: center;
  margin-right: $basespace-100;
}

.b-label-right {
  display: flex;
  align-items: center;
  margin-left: $basespace-100;
}

.disable {
  color: $concrete-dark;
}
</style>
