<template>
  <BLayout
    column
    class="my-600"
  >
    <h3 class="mb-300">
      {{ $t('import.salesforce.describeTitle') }}
    </h3>
    <BLayout
      align-center
    >
      <div>
        {{ $t('import.salesforce.nameTargetObject') }}
      </div>
      <BInput
        v-model="targetObject"
        class="mx-200"
        :placeholder="$t('general.search.placeholder')"
        :disabled="loading"
      />
      <BBtn
        size="small"
        type="primary"
        :disabled="loading"
        @click="search"
      >
        <span>{{ $t('general.search.exec') }}</span>
      </BBtn>
    </BLayout>
    <BLayout
      row
      class="my-600 separate-layout"
    >
      <div
        v-if="Object.keys(describe).length > 0"
        class="import-confirm-list my-200"
      >
        <p>{{ $t('import.salesforce.describeTitle') }}</p>
        <pre>{{ describe }}</pre>
      </div>
      <div
        v-if="Object.keys(describeLayouts).length > 0"
        class="import-confirm-list my-200"
      >
        <p>{{ $t('import.salesforce.describeLayoutTitle') }}</p>
        <pre>{{ describeLayouts }}</pre>
      </div>
    </BLayout>
  </BLayout>
</template>
  
<script setup lang="ts">
import { ref } from 'vue';
import api from '@/api/admin/resources/salesforce';
  
  type TProps = {
    id: number;
  };
const props = defineProps<TProps>();
  
const loading = ref<boolean>(false);
const targetObject = ref<string>('');
const describe = ref<object>({});
const describeLayouts = ref<object>({});
  
const errorHandler = (error) => {
  describe.value = {};
  describeLayouts.value = {};
  loading.value = false;
};
  
const search = async () => {
  loading.value = true;
  describe.value = {};
  describeLayouts.value = {};
  const result = await api.searchSalesforceDescribe({
    targetObject: targetObject.value,
    clientId: props.id,
  }).catch(errorHandler);
  if (result == undefined) return;

  describe.value = JSON.stringify(result.data.salesforce.describe, null, 2);
  describeLayouts.value = JSON.stringify(result.data.salesforce.describeLayouts, null, 2);
  loading.value = false;
};
</script>
  
<style lang="scss" scoped>
.separate-layout {
  width: 100%;
  div {
    width: 50%;
    overflow-x: scroll;
  }
}
</style>
