/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateSfImportObjectId200Response } from '../models';
// @ts-ignore
import { CreateSfImportObjectIdRequest } from '../models';
// @ts-ignore
import { GetSfImportObjectId200Response } from '../models';
/**
 * SfImportApi - axios parameter creator
 * @export
 */
export const SfImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * SalesforceオブジェクトIDからの取り込んで作成API
         * @summary SalesforceオブジェクトIDからの取り込んで作成API
         * @param {CreateSfImportObjectIdRequest} [createSfImportObjectIdRequest] SalesfoeceインポートのリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSfImportObjectId: async (createSfImportObjectIdRequest?: CreateSfImportObjectIdRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/salesforce/sf_import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSfImportObjectIdRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SalesforceオブジェクトID取得API
         * @summary SalesforceオブジェクトID取得API
         * @param {string} sfId salesforce object ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSfImportObjectId: async (sfId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sfId' is not null or undefined
            assertParamExists('getSfImportObjectId', 'sfId', sfId)
            const localVarPath = `/api/user/salesforce/sf_import/{sfId}`
                .replace(`{${"sfId"}}`, encodeURIComponent(String(sfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SfImportApi - functional programming interface
 * @export
 */
export const SfImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SfImportApiAxiosParamCreator(configuration)
    return {
        /**
         * SalesforceオブジェクトIDからの取り込んで作成API
         * @summary SalesforceオブジェクトIDからの取り込んで作成API
         * @param {CreateSfImportObjectIdRequest} [createSfImportObjectIdRequest] SalesfoeceインポートのリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSfImportObjectId(createSfImportObjectIdRequest?: CreateSfImportObjectIdRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateSfImportObjectId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSfImportObjectId(createSfImportObjectIdRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SalesforceオブジェクトID取得API
         * @summary SalesforceオブジェクトID取得API
         * @param {string} sfId salesforce object ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSfImportObjectId(sfId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSfImportObjectId200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSfImportObjectId(sfId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SfImportApi - factory interface
 * @export
 */
export const SfImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SfImportApiFp(configuration)
    return {
        /**
         * SalesforceオブジェクトIDからの取り込んで作成API
         * @summary SalesforceオブジェクトIDからの取り込んで作成API
         * @param {CreateSfImportObjectIdRequest} [createSfImportObjectIdRequest] SalesfoeceインポートのリクエストBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSfImportObjectId(createSfImportObjectIdRequest?: CreateSfImportObjectIdRequest, options?: any): AxiosPromise<CreateSfImportObjectId200Response> {
            return localVarFp.createSfImportObjectId(createSfImportObjectIdRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * SalesforceオブジェクトID取得API
         * @summary SalesforceオブジェクトID取得API
         * @param {string} sfId salesforce object ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSfImportObjectId(sfId: string, options?: any): AxiosPromise<GetSfImportObjectId200Response> {
            return localVarFp.getSfImportObjectId(sfId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SfImportApi - interface
 * @export
 * @interface SfImportApi
 */
export interface SfImportApiInterface {
    /**
     * SalesforceオブジェクトIDからの取り込んで作成API
     * @summary SalesforceオブジェクトIDからの取り込んで作成API
     * @param {CreateSfImportObjectIdRequest} [createSfImportObjectIdRequest] SalesfoeceインポートのリクエストBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfImportApiInterface
     */
    createSfImportObjectId(createSfImportObjectIdRequest?: CreateSfImportObjectIdRequest, options?: AxiosRequestConfig): AxiosPromise<CreateSfImportObjectId200Response>;

    /**
     * SalesforceオブジェクトID取得API
     * @summary SalesforceオブジェクトID取得API
     * @param {string} sfId salesforce object ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfImportApiInterface
     */
    getSfImportObjectId(sfId: string, options?: AxiosRequestConfig): AxiosPromise<GetSfImportObjectId200Response>;

}

/**
 * Request parameters for createSfImportObjectId operation in SfImportApi.
 * @export
 * @interface SfImportApiCreateSfImportObjectIdRequest
 */
export interface SfImportApiCreateSfImportObjectIdRequest {
    /**
     * SalesfoeceインポートのリクエストBody
     * @type {CreateSfImportObjectIdRequest}
     * @memberof SfImportApiCreateSfImportObjectId
     */
    readonly createSfImportObjectIdRequest?: CreateSfImportObjectIdRequest
}

/**
 * Request parameters for getSfImportObjectId operation in SfImportApi.
 * @export
 * @interface SfImportApiGetSfImportObjectIdRequest
 */
export interface SfImportApiGetSfImportObjectIdRequest {
    /**
     * salesforce object ID
     * @type {string}
     * @memberof SfImportApiGetSfImportObjectId
     */
    readonly sfId: string
}

/**
 * SfImportApi - object-oriented interface
 * @export
 * @class SfImportApi
 * @extends {BaseAPI}
 */
export class SfImportApi extends BaseAPI implements SfImportApiInterface {
    /**
     * SalesforceオブジェクトIDからの取り込んで作成API
     * @summary SalesforceオブジェクトIDからの取り込んで作成API
     * @param {SfImportApiCreateSfImportObjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfImportApi
     */
    public createSfImportObjectId(requestParameters: SfImportApiCreateSfImportObjectIdRequest = {}, options?: AxiosRequestConfig) {
        return SfImportApiFp(this.configuration).createSfImportObjectId(requestParameters.createSfImportObjectIdRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SalesforceオブジェクトID取得API
     * @summary SalesforceオブジェクトID取得API
     * @param {SfImportApiGetSfImportObjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SfImportApi
     */
    public getSfImportObjectId(requestParameters: SfImportApiGetSfImportObjectIdRequest, options?: AxiosRequestConfig) {
        return SfImportApiFp(this.configuration).getSfImportObjectId(requestParameters.sfId, options).then((request) => request(this.axios, this.basePath));
    }
}
