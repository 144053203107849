<template>
  <MailDrawerTemplate :title="$t('mailTemplate.confirmContent')">
    <template #drawerHeaderAction>
      <BBtn
        size="small"
        type="primary"
        outline
        @click="$emit('complete')"
      >
        <span>{{ $t("general.save.text") }}</span>
        <BIcon>keyboard_arrow_right</BIcon>
      </BBtn>
    </template>
    <template #drawerBody>
      <div class="drawer-body">
        <BLayout
          class="template-name-confirm mb-400"
          align-center
        >
          <div>{{ mailTemplate.name }}</div>
        </BLayout>
        <div class="template-confirm mb-600">
          <div class="subject-confirm">
            {{ mailTemplate.subject }}
          </div>
          <div class="content-confirm">
            <div
              class="quill-confirm"
              v-html="mailTemplate.content"
            />
          </div>
        </div>
        <BListItem
          v-if="!!senderSet"
          class="list-item mb-600"
        >
          <template #header>
            <div>{{ $t("mailTemplate.fromName") }}</div>
          </template>
          <span>{{ senderSet }}</span>
        </BListItem>
        <BListItem
          v-if="!isDisabledReplyForm"
          class="list-item"
        >
          <template #header>
            <div>{{ $t("mailTemplate.reply") }}</div>
          </template>
          <span>{{ replySet }}</span>
        </BListItem>
      </div>
    </template>
    <template #drawerFooter>
      <BLayout justify-center>
        <BBtn
          class="mr-100"
          @click="$emit('to-form')"
        >
          <BIcon>keyboard_arrow_left</BIcon><span>{{ $t("mail.backToEdit") }}</span>
        </BBtn>
      </BLayout>
    </template>
  </MailDrawerTemplate>
</template>

<script>
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue';

export default {
  name: 'ConfirmDrawer',
  components: {
    MailDrawerTemplate,
  },
  props: {
    mailTemplate: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'complete',
    'to-form',
  ],
  computed: {
    isDisabledReplyForm() {
      return !this.mailTemplate.replyName && !this.mailTemplate.replyAddress;
    },
    senderSet() {
      if (this.mailTemplate.fromName && this.mailTemplate.fromAddress) {
        return `${this.mailTemplate.fromName} <${this.mailTemplate.fromAddress}>`;
      }
      return this.mailTemplate.fromName || this.mailTemplate.fromAddress || null;
    },
    replySet() {
      return `${this.mailTemplate.replyName} <${this.mailTemplate.replyAddress}>`;
    },
  },
};
</script>

<style lang="scss" scoped>
.template-name-confirm {
  margin-top: -$basespace-400;
  margin-right: -$basespace-400;
  margin-left: -$basespace-400;
  padding: $basespace-200 $basespace-400;
  background-color: $concrete-light;
}

.template-confirm {
  border: 1px solid $concrete;
  border-radius: 5px;

  .subject-confirm {
    min-height: 32px;
    max-height: 80px;
    overflow-y: scroll;
    border-bottom: 1px solid $concrete;
    padding: $basespace-100;
    font-weight: 600;
  }

  .content-confirm {
    max-height: 600px;
    overflow-y: scroll;
    padding: $basespace-100;
  }
}

.quill-confirm {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 1.42;
  tab-size: 4;
  text-align: left;

  // Biscuetの標準リンク色を上書き
  :deep(a) {
    color: #06c;
  }

  :deep(p) {
    margin: 0 !important;
    padding: 0 !important;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
      list-9 !important;
  }
}
</style>
