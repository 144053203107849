<template>
  <BIconWithActionMenu
    class="trigger-require-approval-notification-container"
    :width="900"
    :badge-count="paginationMeta?.totalCount || 0"
    :auto-fetch-interval="30000"
    @auto-fetch-interval-passed="fetchLoadedPage"
    @after-open="handleAfterOpen"
    @after-close="clear"
  >
    <template #icon>
      <BIcon outlined>
        how_to_reg
      </BIcon>
    </template>
    <div class="header">
      <div class="header-item header-title">
        {{ $t('triggerRequireApprovalNotification.title') }}
      </div>
      <div class="header-item number-of-items">
        <span>{{ paginationMeta?.totalCount || 0 }}{{ $t('general.matter') }}</span>
      </div>
    </div>
    <div
      class="body"
    >
      <div
        v-infinite-scroll="loadMore"
        class="body-inner"
        :infinite-scroll-disabled="infiniteScrollDisabled"
        :infinite-scroll-distance="PER_PAGE"
      >
        <BEmptyBox
          v-if="!$wait.is(KEY_OF_INFINITE_LOADING_WAIT) && !items.length"
          class="empty"
          display-only
        >
          {{ $t('triggerRequireApprovalNotification.empty') }}
        </BEmptyBox>
        <template v-else>
          <div class="body-header">
            <div class="company-name">
              {{ $t('callTarget.column.companyName') }}
            </div>
            <div class="trigger-name">
              {{ $t('trigger.name') }}
            </div>
            <div class="date">
              {{ $t('triggerRequireApprovalNotification.createdAt') }}
            </div>
            <div class="actions">
              {{ $t('general.approve.text') }}
            </div>
          </div>
          <TriggerRequireApprovalNotificationItem
            v-for="item in items"
            :key="item.id"
            :item="item"
            :loading="loading"
            @after:update="fetchLoadedPage"
          />
        </template>
        <BLayout
          v-if="$wait.is(KEY_OF_INFINITE_LOADING_WAIT)"
          class="py-200"
          justify-center
        >
          <BElementIcon name="Loading" />
        </BLayout>
      </div>
    </div>
  </BIconWithActionMenu>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { TriggerTargetAction } from '@/api/openapi';
import { TriggerTargetActionApiService } from '@/api/user/resources/trigger_target_action';
import { useInfiniteLoading } from '@/composable/infinite-loading';
import { useWait } from '@/composable/vue-wait';
import TriggerRequireApprovalNotificationItem from './triggerRequireApprovalNotification/TriggerRequireApprovalNotificationItem.vue';

const { wait } = useWait();

const PER_PAGE = 20;
const KEY_OF_INFINITE_LOADING_WAIT = 'triggerRequireApprovalNotificationInfiniteLoadingWait';
const loading = computed(() => wait.is([KEY_OF_INFINITE_LOADING_WAIT, 'triggerApproveWait', 'triggerRejectWait']));

const api = new TriggerTargetActionApiService();
const fetchItems = async (page = 1) => {
  const { data: { triggerTargetActions, meta } } = await api.getTriggerTargetActionApprovals({
    request: {
      page,
    },
  });
  return {
    items: triggerTargetActions,
    paginationMeta: meta,
  };
};

const {
  items,
  paginationMeta,
  infiniteScrollDisabled,
  loadMore,
  clear,
  handleAfterOpen,
  fetchLoadedPage,
} = useInfiniteLoading<TriggerTargetAction>(fetchItems, KEY_OF_INFINITE_LOADING_WAIT);
</script>

<style lang="scss" scoped>
.trigger-require-approval-notification-container {
  .header {
    display: flex;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid $bdcolor-base;
    padding: 0 $basespace-400;

    &-item {
      margin: $basespace-250 0;

      &.header-title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: $basespace-500 0;
      }

      &.number-of-items {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: $basespace-500 0;

        span {
          display:inline-block;
          vertical-align: middle;
          text-align: center;
          width: 54px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin: 0 $basespace-400;
        }
      }
    }
  }

  @mixin row {
    display: flex;
    text-decoration: none;
    align-items: center;
    border-bottom: 1px solid $bdcolor-light;
    width: 100%;
  }

  @mixin columns {
    > div {
      padding: 0 $basespace-200;
    }
    .company-name, .trigger-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .company-name {
      width: 300px;
      padding-left: $basespace-400;
    }
    .trigger-name {
      width: 250px;
    }
    .date {
      @include m-fixed-width(140px);
    }
    .actions {
      @include m-fixed-width(190px);
      padding-left: $basespace-400;
      padding-right: $basespace-400;
    }
  }

  .body {
    @include m-fixed-height(calc(580px - 70px - 60px));
    overflow-y: auto;
    overflow-x: auto;

    .body-inner {
      width: 100%;
      min-width: max-content;
    }

    .body-header {
      padding: $basespace-200 0;
      color: $textcolor-light;
      font-size: $fontsize-100;
      @include row;
      @include columns;
    }

    .empty {
      color: $textcolor-light;
      padding: $basespace-600;
      margin: $basespace-600;
      height: 90px;
      font-size: 12px;
    }
  }
  :deep(.trigger-require-approval-notification-item) {
    color: $textcolor-base;
    @include row;
    @include columns;

    > div {
      padding-top: $basespace-200;
      padding-bottom: $basespace-200;
      height: 64px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
