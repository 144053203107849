<template>
  <div class="signin-container">
    <div
      v-loading="$wait.is('USER_SIGNIN_WAIT')"
      class="signin-card"
    >
      <div class="signin-card-inner">
        <div class="signin-symbol-icon">
          <img
            :src="symbolIconSrc"
            height="103"
          >
        </div>
        <div class="signin-logo">
          <img :src="logoSrc">
        </div>
        <div class="signin-form">
          <form @submit.prevent="signin">
            <div class="mb-200">
              <label for="email" />
              <BInput
                v-model="email"
                v-model:valid="emailValid"
                :placeholder="$t('auth.email')"
                color="focus"
                data-test="email"
                input-id="email"
              />
            </div>
            <div class="mb-600">
              <label for="password" />
              <BInput
                v-model="password"
                v-model:valid="passWordValid"
                :type="passwordShow ? 'text' : 'password'"
                name="password"
                :placeholder="$t('auth.password')"
                color="focus"
                data-test="password"
                input-id="password"
                @click:append="passwordShow = !passwordShow"
                @keypress-enter="signin"
              />
            </div>
            <BLayout
              class="mb-300"
              justify-center
            >
              <BBtn
                class="signin-btn"
                type="primary"
                data-test="signinBtn"
                btn-type="submit"
                :disabled="!emailValid || !passWordValid"
                @click.prevent="signin"
              >
                <span>{{ $t('auth.signin') }}</span>
              </BBtn>
            </BLayout>
          </form>
          <BLayout
            align-center
            justify-center
          >
            <BBtn text>
              <RouterLink :to="{ name: 'UserResetPassword' }">
                {{ $t('auth.resetPassword') }}
              </RouterLink>
            </BBtn>
          </BLayout>
        </div>
      </div>
      <div class="signin-card-footer">
        <a
          href="https://smartcamp.co.jp/privacy_policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('org.privacyPolicy') }}
        </a>
        <a
          href="https://balescloud.jp/tos"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('org.termsOfUse') }}
        </a>
      </div>
      <MfaModal
        :modal-name="mfaModalName"
        :multi-factor-error="multiFactorError"
        @close="$modal.hide(mfaModalName)"
        @authenticated="handleAuthenticated"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import symbolIconSrc from '@/assets/bales_cloud_fire_signin.svg';
import logoSrc from '@/assets/brand_logo.svg';
import MfaModal from '@/components/organisms/user/general/modal/MfaModal.vue';
import inputValidation from '@/mixins/input_validation';
import UserService from '@/services/user_service';

export default defineComponent({
  components: {
    MfaModal,
  },
  mixins: [inputValidation],
  setup() {
    return {
      symbolIconSrc,
      logoSrc,
    };
  },
  data() {
    return {
      color: 'blue',
      email: '',
      password: '',
      passwordShow: false,
      loading: false,
      emailValid: false,
      passWordValid: false,
      multiFactorError: null,
      mfaModalName: 'mfaModal',
    };
  },
  created() {
    this.errorCheck();
    this.userService = new UserService();
  },
  methods: {
    errorCheck() {
      const errorName = localStorage.getItem('errorName');
      if (errorName === 'clientExpiredError') {
        const errorClientName = localStorage.getItem('errorClientName');
        this.$bitterAlert.show({
          title: this.$t('auth.error.clientExpired'),
          text: errorClientName + this.$t('auth.errorMessage.clientExpired'),
          buttonsCancel: false,
        });
        localStorage.removeItem('errorClientName');
      }
      localStorage.removeItem('errorName');
    },
    async signin() {
      this.$wait.start('USER_SIGNIN_WAIT');
      try {
        await this.userService.signinUser(this.email, this.password);
        const destination = this.$route.query.dest_location ? this.$route.query.dest_location : { name: 'Home' };
        this.$router.push(destination);
      } catch (error) {
        if (error.code === 'auth/multi-factor-auth-required') {
          this.multiFactorError = error;
          this.$modal.show(this.mfaModalName);
        } else {
          let bitterAlertText = error.message;
          switch (error.code) {
            case 'auth/invalid-credential':
            case 'auth/invalid-email':
            case 'auth/wrong-password':
            case 'auth/user-not-found':
              bitterAlertText = this.$t('auth.errorMessage.wrongEmailOrPassword');
              break;
            case 'auth/user-disabled':
              bitterAlertText = this.$t('auth.errorMessage.userDisabled');
              break;
            case 'auth/too-many-requests':
              bitterAlertText = this.$t('auth.errorMessage.tooManyRequests');
              break;
            default:
              if (error.response && error.response.status === 403) {
                bitterAlertText = this.$t('auth.error.clientExpired');
              } else if (error.response && error.response.status === 404) {
                bitterAlertText = this.$t('auth.errorMessage.wrongEmailOrSlug');
              }
          }
          this.$bitterAlert.show({
            title: this.$t('auth.error.signinError'),
            text: bitterAlertText,
            closeOnClickOutside: true,
            buttonsCancel: false,
          });
        }
      } finally {
        this.$wait.end('USER_SIGNIN_WAIT');
      }
    },
    async handleAuthenticated() {
      await this.userService.signin();
      const destination = this.$route.query.dest_location ? this.$route.query.dest_location : { name: 'Home' };
      this.$router.push(destination);
      this.$modal.hide(this.mfaModalName);
    },
  },
});
</script>

<style lang="scss" scoped>
.signin-container {
  background-image: url('@/assets/signin-background_2.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  background-color: #f3fdff;
  min-height: 100vh;

  .signin-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba($paper, 0.9);
    width: 480px;
    min-height: 100vh;
    padding-top: 60px;
    margin: 0 auto;
    position: relative;
    box-shadow: $boxshadow-base;

    &-inner {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      width: 280px;
      margin: 0 auto;
    }
    &-footer {
      background-color: rgba($paper, 0.9);
      text-align: center;
      padding: $basespace-700 0;
      a {
        color: $textcolor-light;
        text-decoration: underline;
      }
      a:not(:first-child) {
        margin-left: $basespace-400;
      }
    }
  }
}

.signin-message {
  text-align: center;
  font-weight: bold;
  font-size: $fontsize-700;
}

.signin-symbol-icon {
  margin: 0 auto;
}

.signin-logo {
  margin: $basespace-800 auto 60px auto;

  img {
    width: 100%;
    height: 60px;
  }
}

.signin-btn {
  width: 100%;
  margin-left: $basespace-400;
  margin-right: $basespace-400;
}
</style>
