<template>
  <BMenu
    v-model="isShow"
    v-loading="loading"
    placement="bottom-end"
  >
    <template #activator>
      <BBtn
        class="placeholder-menu-button"
        size="mini"
        fab
        shadow
      >
        <BIcon size="small">
          {{ $t('add') }}
        </BIcon>
      </BBtn>
    </template>
    <BCard
      class="placeholder-menu"
      small
    >
      <div class="mb-200">
        <span class="fw-bold mr-100">
          {{ $t('placeholderMenu.title') }}
        </span>
        <BTooltip
          bottom
          :content="$t('placeholderMenu.description')"
        >
          <BIcon
            size="small"
          >
            {{ $t('help') }}
          </BIcon>
        </BTooltip>
      </div>
      <BInput
        v-model="searchText"
        class="mb-300"
        :placeholder="$t('placeholderMenu.placeholder')"
      />
      <div class="placeholder-list">
        <div v-if="isMatching">
          <div
            v-for="placeholderGroup in filteredPlaceholders"
            :key="placeholderGroup.name"
          >
            <div class="placeholder-title">
              {{ placeholderGroup.name }}
            </div>
            <div
              v-for="placeholder in placeholderGroup.placeholders"
              :key="placeholder.key"
            >
              <BBtn
                class="placeholder-button"
                flat
                fit
                position="left"
                :disabled="disablePlaceholder(placeholder.key)"
                @click="() => insertItem(placeholder.key)"
              >
                <div class="placeholder-item">
                  <div>{{ placeholder.key }}</div>
                  <div>{{ placeholder.name }}</div>
                </div>
              </BBtn>
            </div>
          </div>
        </div>
        <div
          v-else
          class="text-annotation"
        >
          {{ $t('general.noCandidate') }}
        </div>
      </div>
    </BCard>
  </BMenu>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import { ApiArguments } from '@/api/api_service';
import { PlaceholderApiGetPlaceholdersRequest, PlaceholderGroup } from '@/api/openapi';
import { PlaceholderApiService } from '@/api/user/resources/placeholder';
import { useWait } from '@/composable/vue-wait';
import { i18nGlobal as i18n } from '@/i18n';

const props = withDefaults(defineProps<TProps>(), {
  isMail: false,
  unsubscribeVisible: false,
  content: '',
});

const emit = defineEmits<TEmits>();

const UNSUBSCRIBE_PLACEHOLDER = '{{Unsubscribe}}';

const { doActionWithWait, wait } = useWait();

type TProps = {
  isMail?: boolean;
  unsubscribeVisible?: boolean;
  content?: string;
};
type TEmits = {
  'insertItem': [content: string];
};

const isShow = defineModel<boolean>({ default: true });
const searchText = ref('');
const placeholderGroups = ref<PlaceholderGroup[]>([]);

onMounted(() => {
  fetchData();
});

const fetchData = async () => {
  await doActionWithWait('fetchPlaceholders', async () => {
    const api = new PlaceholderApiService();
    const apiArguments: ApiArguments<PlaceholderApiGetPlaceholdersRequest> = {
      request: {
        isMail: props.isMail,
      },
    };
    const { data } = await api.getPlaceholders(apiArguments);
    placeholderGroups.value = data.placeholderGroups;
  });
};

const loading = computed(() => {
  return wait.is('fetchPlaceholders');
});

const filteredPlaceholders = computed(() => {
  const result = [];
  for (const placeholderGroup of placeholderGroups.value) {
    const filtered = placeholderGroup.placeholders.filter((placeholder) => {
      if (placeholder.key === UNSUBSCRIBE_PLACEHOLDER && !props.unsubscribeVisible) {
        return false;
      }
      return placeholder.key.includes(searchText.value) || placeholder.name.includes(searchText.value);
    });
    if (filtered.length > 0) {
      result.push({ name: placeholderGroup.name, placeholders: filtered } as PlaceholderGroup);
    }
  }
  return result;
});
const isMatching = computed(() => filteredPlaceholders.value.length > 0);

const disablePlaceholder = (key: string) => {
  return key === UNSUBSCRIBE_PLACEHOLDER && alreadyContainUnsubscribe.value;
};

const alreadyContainUnsubscribe = computed(() => {
  const regexp = new RegExp('{{Unsubscribe:(.+?)}}', 'g');
  const unsubLinks = props.content.match(regexp);
  return Array.isArray(unsubLinks) && unsubLinks.length > 0;
});

const insertItem = (content: string) => {
  if (content === UNSUBSCRIBE_PLACEHOLDER) {
    content = `{{Unsubscribe:${i18n.t('mail.optOutLink')}}}`;
  }
  emit('insertItem', content);
  isShow.value = false;
};
</script>

<style lang="scss" scoped>
.placeholder-menu-button {
  position: absolute;
  top: 5px;
  right: 16px;
  color: $concrete-dark;
}

.placeholder-menu {
  @include m-fixed-width(380px);

  &.card-small {
    padding-bottom: 0;
  }

  .placeholder-list {
    max-height: 300px;
    overflow: auto;
    padding-bottom: $basespace-200;

    .placeholder-title {
      font-size: $fontsize-100;
      color: $textcolor-light;
      padding-bottom: $basespace-50;
      border-bottom: 1px solid $bdcolor-base;
      margin-top: $basespace-100;
      margin-bottom: $basespace-100;
    }

    .placeholder-button {
      padding: 0;
      height: 42px;

      .placeholder-item {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0 $basespace-50;

        div {
          width: 50%;
          line-height: 1.42;
          white-space: normal;
          max-height: 36px;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          &:first-child {
            padding-right: $basespace-50;
          }
        }
      }
    }
  }
}
</style>
